<kendo-grid #kendoGrid kendoGridFixFreezeHeader class="time-and-attendance exception-console slx-full-height slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  [skip]="gridState.state.skip"
  slxKendoGridState="EXCEPTION_CONSOLE"
>
  <kendo-grid-column media="xs" title="Items" width="500">
    <ng-template kendoGridCellTemplate let-dataItem>
      
      <h4>
        {{ dataItem.employee.name }}
      </h4>
      
      <dl>
        
        <dt>Position</dt>
        <dd>{{ dataItem.position.name }}</dd>

        <dt>TotalExceptionsCount</dt>
        <dd>{{ dataItem.totalExceptionsCount| number: ".0-0" }}</dd>

      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="true" title="Select" [sortable]="false" field="isSelected" media="sm" width="40" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input *ngIf="dataItem.isSelectable && !dataItem.isLocked" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
      <div *ngIf="dataItem.isLocked">
        <i class="fas fa-lock" aria-hidden="true"></i>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="true" [sortable]="false" field="isSelected" media="sm" width="40" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Timecard</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [individualTimecardsLink]="dataItem?.employee.id"
        [startDate]="dataItem.startDate"
        [endDate]="dataItem.endDate"
      >
        <i class="fas fa-calendar-alt" aria-hidden="true"></i>
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="true" title="Employee Name" width="150" media="sm" field="employee.name">
     <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem?.employee.id">
        {{dataItem?.employee.name}}
      </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!usePayCycle" [locked]="true" title="Payroll Cycle" width="200" media="sm" field="payCycle.description">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll Cycle</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="onPaycycleClicked(dataItem)">
        {{dataItem?.payCycle?.description}}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" kendoGridColumnId="position" media="sm" field="position.name">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.position.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" kendoGridColumnId="missingPunches" media="sm" field="missingPunches"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pending/Approved Missing Punches</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.missingPunches}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['pendingMissingPunchesCount']?.sum  | number: ".0-0"}}/{{total['approvedMissingPunchesCount']?.sum  | number: ".0-0"}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" kendoGridColumnId="totalExceptionsCount" media="sm" field="totalExceptionsCount"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Exceptions Count</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalExceptionsCount| number: ".0-0"}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['totalExceptionsCount']?.sum  | number: ".0-0"}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let col of state.dynamicColumns" 
    [kendoGridColumnConfig]="state.columnsMap"
    [kendoGridColumnId]="col.name" media="sm" 
    class="numeric-cell" footerClass="numeric-cell"
    [sortable]="'true'"
    [field]="col.payload.exceptionField"
    >

      <ng-template kendoGridHeaderTemplate  let-column="column">
        <span slxKendoGridHeaderTooltip>{{column.title}}</span>
      </ng-template>

      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-column="column">
          {{ dataItem.getExceptionCount (col.name) | number: ".0-0" }}
      </ng-template>

      <ng-template kendoGridFooterTemplate let-column>
        <strong>{{ total [col.name] | number: ".0-0"}}</strong>
      </ng-template>
      
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>

  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>
