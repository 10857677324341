<kendo-grid class="slx-full-height slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageSize]="pageSize"
  [pageable]="true"
  [skip]="gridState.state.skip"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (filterChange)="filterChange($event)"
  [scrollable]="'scrollable'"
>
  <kendo-grid-column title="Date/Time" locked="false" field="auditDateTime" filter="date">
    <ng-template kendoGridHeaderTemplate>
      Date/Time
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.auditDateTime | amDateFormat: appConfig.dateTimeFormatUS}} {{useTZnotOffset ? currentTimezoneCode : ''}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="User" field="userLoginId" locked="false">
    <ng-template kendoGridHeaderTemplate>
      User
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.userLoginId}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Action" field="action" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Action
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <div  class="audit-trail">
          <div *ngIf="dataItem.actionType">{{dataItem.actionType}}. </div>{{dataItem.fieldName | slxAuditTrail : ''}} changed from {{dataItem.valueBefore | slxAuditTrail}} to {{dataItem.valueAfter | slxAuditTrail}}
        </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Field Name" field="fieldName" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Field Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.fieldName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Value Before" field="valueBefore" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Value Before
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <div  class="audit-trail">
          {{dataItem.valueBefore  | slxAuditTrail : ''}}
        </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Value After" field="valueAfter" locked="false">
    <ng-template kendoGridHeaderTemplate>
      Value After
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <div  class="audit-trail">
          {{dataItem.valueAfter  | slxAuditTrail : ''}}
        </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Applicable/Effective Date" locked="false" [filterable]="false" field="effectiveDate">
    <ng-template kendoGridHeaderTemplate>
      Applicable/Effective Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.effectiveDate | amDateFormat: appConfig.dateFormat}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>
