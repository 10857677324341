<kendo-grid #kendoGrid
            class="emp-grid slx-blue-grid slx-transparent-selection"
            slxKendoGridState="EMPLOYEE_LIST"
            [filtersManagement]="true"
            [slxKendoGridColumnsGroup]="columnsGroupName"
            [data]="gridState.view"
            [pageSize]="pageSize"
            [skip]="gridState.state.skip"
            [pageable]="isPaging"
            [sortable]="{ mode: 'single' }"
            [groupable]="isGroupable"
            [filterable]="'menu'"
            [group]="gridState.state.group"
            [sort]="gridState.state.sort"
            [filter]="gridState.state.filter"
            (dataStateChange)="dataStateChange($event)"
            (stateRestored)="dataStateChange($event)"
            [selectable]="true"
            [rowSelected]="isRowSelected()"
            (cellClick)="onCellClick($event)"
            (excelExport)="onExcelExport($event)">

  <kendo-grid-column *ngIf="isOptInStatus" [width]="40"
                     [sortable]="false"
                     [filterable]="false"
                     [locked]="false">
    <ng-template kendoGridHeaderTemplate>
      <i class="fas fa-paper-plane icon-sent-sms" aria-hidden="true" title="Message was already sent or not"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <i *ngIf="dataItem.OptIn && dataItem.OptInDate" aria-hidden="true"
         title="SMS sent at {{ dataItem.OptInDate | amDateFormat: appConfig.dateTimeFormatUS }}"
         class="fas fa-paper-plane icon-sent-sms"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="isOptInEmail" [width]="40"
                     [sortable]="false"
                     [filterable]="false"
                     [locked]="false">
    <ng-template kendoGridHeaderTemplate>
      <i class="fas fa-paper-plane icon-sent-sms" aria-hidden="true" title="Email was already sent or not"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <i *ngIf="dataItem.OptInEmailDate" aria-hidden="true"
         title="Email sent at {{ dataItem.OptInEmailDate | amDateFormat: appConfig.dateTimeFormatUS }}"
         class="fas fa-paper-plane icon-sent-sms"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="isMultiselect" title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
                     width="40px">
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input *ngIf="dataItem.Tooltip" [disabled]="!dataItem.Selectable" title="{{ dataItem.Tooltip }}" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
      <input *ngIf="!dataItem.Tooltip" [disabled]="!dataItem.Selectable" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
    </ng-template>
  </kendo-grid-column>
  <ng-template ngFor let-fieldDef [ngForOf]="currentFieldDefinition?.fields">
    <kendo-grid-column [locked]="(fieldDef.fieldName === 'EmpName' || fieldDef.fieldName === 'OptIn' || fieldDef.fieldName === 'OptInDate') && isColumnLocked" *ngIf="!(fieldDef.hidden) && fieldDef.fieldName!='Selectable'" [filterable]="!fieldDef.masked" filter="{{getFilter(fieldDef.fieldType)}}"
                       title="{{fieldDef.displayName}}" field="{{fieldDef.fieldName}}" width="{{fieldDef.width}}" slxKendoGridColumnHiddenState [supressRegistration]="isColumnSuppressed(fieldDef)" class="{{fieldDef.fieldType==='number'?'numeric-cell':''}}" format="{{fieldDef.fieldType==='datetime'?'{0:MM/dd/yyyy hh:mm a}':''}}">
      <ng-template kendoGridHeaderTemplate>
        {{fieldDef.displayName}}
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <div [ngSwitch]="fieldDef.fieldType">
          <strong *ngSwitchCase="'date'">{{value| amDateFormat: appConfig.dateFormat}}</strong>
          <strong *ngSwitchCase="'datetime'">{{value| amDateFormat: appConfig.dateFormat}}</strong>
          <strong *ngSwitchDefault>{{value}}</strong>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngSwitch]="fieldDef.fieldType">
          <span *ngIf="(fieldDef.displayName ==='Employee Contribution' || fieldDef.displayName === 'Employer Contribution' || fieldDef.displayName === 'Cost')">
            <span *ngIf="notBlank(dataItem, fieldDef.displayName);">{{'$'}} </span>
          </span>
          <span *ngSwitchCase="'date'">
            <ng-container *ngIf="!fieldDef.masked">{{dataItem | property: fieldDef.fieldName | amDateFormat: appConfig.dateFormat }}</ng-container>
            <ng-container *ngIf="fieldDef.masked">{{ dataItem | property: fieldDef.fieldName }}</ng-container>
          </span>
          <span *ngSwitchCase="'datetime'">
            <ng-container *ngIf="!fieldDef.masked">
              <span *ngIf="fieldDef.fieldName === 'OptInDate'">
                {{dataItem | property: fieldDef.fieldName | amDateFormat: appConfig.dateTimeFormatUS }}
                </span>
                <span *ngIf="fieldDef.fieldName !== 'OptInDate'">
                  {{dataItem | property: fieldDef.fieldName | amDateFormat: appConfig.dateTimeFormat }}
                </span>
            </ng-container>
            <ng-container *ngIf="fieldDef.masked">{{ dataItem | property: fieldDef.fieldName }}</ng-container>
          </span>
          <a *ngSwitchCase="'hyperlink'" [employeeSectionLink]="dataItem | property: employeeListConfig.employeeIdentifierName">
            {{dataItem | property: fieldDef.fieldName | slxMaxChars : 20 : useNameRestriction && fieldDef.fieldName === 'EmpName'}}
          </a>
          <span *ngSwitchCase="'boolean'">{{ dataItem | property: fieldDef.fieldName | boolyn: true }}</span>
          <span *ngSwitchCase="'number'">{{ dataItem | property: fieldDef.fieldName | number: ".2-2" }}</span>
          <div *ngSwitchCase="'string'" >
            <div class="optin-icon optin-col-cls full-width" *ngIf="fieldDef.fieldName === 'OptIn' && dataItem.OptIn" [ngClass]="{'sms-opt-in-tooltip':dataItem.OptIn === 'Opted In','sms-opt-out-tooltip':dataItem.OptIn == 'Opted Out','sms-opt-tooltip':dataItem.OptIn == 'Not Responded to the Opt In Request'}">
              <span [slxTooltip]="dataItem.OptInStatusMessage" tipPosition="right">
                <i class="fas fa-mobile-alt mobile-alt" [ngClass]="{'sms-opt-in':dataItem.OptIn === 'Opted In','sms-opt-out':dataItem.OptIn == 'Opted Out','sms-opt':dataItem.OptIn == 'Not Responded to the Opt In Request'}" aria-hidden="true"></i>
              </span>
            </div>
            <span *ngIf="fieldDef.fieldName !== 'OptIn'" class="emp-grid__text">
              {{ dataItem | property: fieldDef.fieldName }}
            </span>
          </div>
          <span *ngSwitchDefault class="emp-grid__text">
            {{ dataItem | property: fieldDef.fieldName }}
          </span>
          <i *ngIf="dataItem[fieldDef.fieldName]<0 && (fieldDef.displayName =='Employee Contribution'|| fieldDef.displayName == 'Employer Contribution')"
             title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
             aria-hidden="true"
             [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
        </div>
      </ng-template>
      <ng-template *ngIf="fieldDef.fieldType!=='date' && fieldDef.fieldType!=='datetime' && fieldDef.fieldType!=='number' && fieldDef.fieldType!=='int' && fieldDef.fieldType!=='tinyint'" kendoGridFilterCellTemplate
                   let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
      <ng-template *ngIf="fieldDef.fieldType==='date'" kendoGridFilterCellTemplate let-filter
                   let-column="column">
        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
      </ng-template>
      <ng-template *ngIf="fieldDef.fieldType==='datetime'" kendoGridFilterCellTemplate let-filter
                   let-column="column">
        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
      </ng-template>
      <ng-template *ngIf="fieldDef.fieldType ==='number' || fieldDef.fieldType === 'int' " kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
         <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [format]="getFormat(fieldDef.fieldFormat)">
         </kendo-grid-numeric-filter-menu>
      </ng-template>
      <ng-template *ngIf="fieldDef.fieldType ==='string' && fieldDef.fieldName === 'OptIn'" kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
        <slx-kendo-grid-multiselect-checkbox-filter [isPrimitive]="false"
                                                    [field]="column.field"
                                                    [currentFilter]="filter"
                                                    [filterService]="filterService"
                                                    textField="text"
                                                    valueField="value"
                                                    [data]="typeFilters">
        </slx-kendo-grid-multiselect-checkbox-filter>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <kendo-grid-excel fileName="{{exportFileName}}.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="{{exportFileName}}.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
  <kendo-grid-messages filterMenuTitle="Filter">
  </kendo-grid-messages>  
</kendo-grid>
