export const environment = {
  production: true,
  API: 'https://slxusc-prd136-v6-app01-be.azurewebsites.net',
  TA_SERVICES_API: "https://v6-dev-api-man.azure-api.net/prod",
  IDENTITY_API_URL: 'https://identityauthprod.smartlinx6.com',
  ENV: 'PROD',
  MARKETING_PAGE_LNK: 'https://v6-marketingpage.smartlinxsolutions.com/login-v6-01.html',
  FROALA_EDITOR_LICENCE: 'fA5C3F3B2C-7J2A4C3B1C6E2D1F4H1F1rA-16C-13pf1gxI-8xoB5A2ytq==',
  GOOGLE_MAPS_API_LICENSE: undefined,
  PENDO_LICENCE: '15c64758-bb83-4e5f-56c6-b77ac15375dc',
  ZENDESK_APIKEY: 'a3b8ed75-379a-4e97-b525-603a8f979d60',
  AG_GRID_LICENCE: 'Smartlinx_Solutions_Smartlinx_2Devs_2Deployment_2_May_2020__MTU4ODM3NDAwMDAwMA==5fbec94ad9c76dbc4aaf97a36d11dfee',
  APP_INSIGHT_INSTRUMENTATION_KEY: '3cb22324-ce40-40c4-a828-b02c8da0b5ef',
  APP_UI_URL: 'https://www.smartlinx6.com'
};
