<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box pay-codes-configuration">

    <!-- toolbar -->
    <div class="slx-content-toolbar-indents flex-container">
      <div class="button-box">
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted"
          *ngIf="!state.bulkEditMode && state.canBulkEdit" (click)="onBulkEditClick()">
          <i class="fas fa-edit slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Edit Mode</span>
        </button>
      </div>
      <div class="buttons-box">
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted"
          *ngIf="!state.bulkEditMode && state.canAdd" [slx-list-add-operation]="onAddItem">
          <i class="fas fa-plus-circle slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Add</span>
        </button>
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted"
          *ngIf="!state.bulkEditMode && state.canDelete" [slx-list-delete-operation]="onDeletedItems">
          <i class="fas fa-trash-alt slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Delete</span>
        </button>
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" *ngIf="state.bulkEditMode"
          (click)="onBulkSaveClick()">
          <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Save</span>
        </button>
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" *ngIf="state.bulkEditMode"
          (click)="onBulkDiscardClick()">
          <i class="fas fa-ban slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Discard</span>
        </button>
        <slx-columns-config-button [colGroupKey]="columnGroup" class="payCode_mleft" *ngIf="!state.bulkEditMode"
          [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
          title="PayCode settings"></slx-columns-config-button>
        <slx-action-list class="payCode_mright" *ngIf="!state.bulkEditMode">
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-actions-list-item *ngIf="state.isSmartAdmin" (onClick)="addNewPayCode()">Add Paycode</slx-actions-list-item>
            <slx-actions-list-item *ngIf="state.isSmartAdmin" (onClick)="handleCopyPayCodeClick()">Copy Paycodes</slx-actions-list-item>
            <slx-actions-list-item *ngIf="state.isSmartAdmin" (onClick)="handlePayPoliciesClick()">Pay/Shift Diff Policy Setup</slx-actions-list-item>
            <slx-actions-list-item (onClick)="onClickExport(false)">Export to Excel</slx-actions-list-item>
            <slx-actions-list-item (onClick)="onClickExport(true)">Export to PDF</slx-actions-list-item>
            <slx-actions-list-item *ngIf="state.isSmartAdmin" (click)="openPopup()">Import Paycode</slx-actions-list-item>
          </popper-content>
          <kendo-dialog *ngIf="isPopupVisible && state.isSmartAdmin" (close)="closePopup()" class="custom-dialog">
            <kendo-dialog-titlebar class="custom-dialog-titlebar">
              Import a Pay Code (JSON)
            </kendo-dialog-titlebar>
            <div class="k-dialog-content custom-dialog-content">
              <input type="file" (change)="onFileChange($event)" accept=".json" class="custom-file-input"/>
              <div *ngIf="errors.length > 0" class="error-messages">
                <ul>
                  <li *ngFor="let error of errors">{{ error }}</li>
                </ul>
              </div>
            </div>
            <div class="k-dialog-buttongroup">
            <div class="button-box">
              <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" (click)="onUpload()" [disabled]="errors.length > 0">
                <i class="fas fa-upload slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Upload</span>
              </button>
            </div>
            <div class="button-box">
              <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" (click)="closePopup()">
                <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text">Cancel</span>
              </button>
            </div>
          </div>
          </kendo-dialog>
        </slx-action-list>
      </div>
    </div>

    <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
      <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height" [data]="gridState?.view" (dataBound)="onDataBound()"
        [selectable]="{ mode: 'single' }" [scrollable]="'virtual'" [rowHeight]="35" [filterable]="'menu'"
        [filter]="gridState.state.filter" [sort]="gridState.state.sort" [sortable]="{ mode: 'multiple' }"
        [skip]="gridState.state.skip" (selectionChange)="gridState.selectionChange($event)"
        (dataStateChange)="gridState.dataStateChange($event)" (cancel)="gridState.cancelHandler($event)"
        (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)" [rowClass]="rowCallback" [rowReorderable]="true"
        [slxKendoGridColumnsGroup]="columnGroup" tabindex="0">
        <!-- desktop view -->
        <kendo-grid-command-column width="90" [locked]="true" media="(min-width: 450px)"
          *ngIf="!state.bulkEditMode && state.canEdit">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
            <div class="command-container">
              <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid"
                class="theme-icon-button theme-inline-apply-button">
                <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column title="Drag & Drop" width="130"  *ngIf="state.isSmartAdmin && state.bulkEditMode">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span
              class="k-icon k-i-reorder"
              draggable="true"
              (dragstart)="onDragStart($event, rowIndex)"
              (dragover)="onDragOver($event, rowIndex)"
              (drop)="onDrop($event, rowIndex)">
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Order" width="120" *ngIf="state.isSmartAdmin && state.bulkEditMode"
        media="(min-width: 450px)" slxKendoGridColumnHiddenState>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="action-buttons">
             <button class="btnUp" (click)="moveRowUp(rowIndex)">
              <i class="k-icon k-i-arrow-up"></i>
             </button>
             <button class="btnDown" (click)="moveRowDown(rowIndex)">
              <i class="k-icon k-i-arrow-down"></i>
             </button>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Pay Code Id" field="payCode.id" [filterable]="true" width="150"
        media="(min-width: 450px)" slxKendoGridColumnHiddenState>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.id"
              [required]="true" [placeholder]="" name="descriptionInput{{rowIndex}}">
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

        <kendo-grid-column title="Name" field="payCode.description" [filterable]="true" width="150"
          media="(min-width: 450px)" slxKendoGridColumnHiddenState>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">
              <ng-container *ngIf="state.isSmartAdmin; else noLinkTemplate">
                <a (click)="handlePayCodeClick($event, dataItem.payCode.id)">{{ dataItem.payCode.description }}</a>
              </ng-container>
              <ng-template #noLinkTemplate>
                {{ dataItem.payCode.description }}
              </ng-template>
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.description"
                [required]="true" [placeholder]="" name="descriptionInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Department" field="payCode.department" [filterable]="true" width="150"
          media="(min-width: 450px)" slxKendoGridColumnHiddenState>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.payCode.department }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.department"
                [required]="true" [placeholder]="" name="departmentInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Color" field="payCode.color" [filterable]="false" width="80"
          media="(min-width: 450px)" class="no-padding">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="col color-cell color-border" [style.background-color]="dataItem.payCode.color | DecHex"></div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="color-picker">
              <slx-input-decorator>
                <input class="hidden-input" type="hidden" slx-input [required]="true" placeholder="">
                <input id="colorField{{rowIndex}}" type="text" class="color-border color-picker-input"
                  [colorPicker]="getColor (dataItem)" [cpPosition]="'bottom'" [cpAlphaChannel]="'disabled'"
                  [cpOutputFormat]="'hex'" [style.background]="getColor (dataItem)"
                  (colorPickerChange)="onColorPickerChange(dataItem, $event)" readonly [cpDialogDisplay]="'popup'"
                  [cpUseRootViewContainer]="true" [placeholder]="''">
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Paid" field="payCode.isPaid" [filterable]="true" width="80" media="(min-width: 450px)"
          slxKendoGridColumnHiddenState>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.isPaid" aria-hidden="true" class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [ngModel]="dataItem.payCode.isPaid"
                (ngModelChange)="onItemFieldChange(dataItem, 'isPaid', $event)" [readonly]="true"
                fieldName="isPaidField{{rowIndex}}" name="isPaid{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [(ngModel)]="dataItem.payCode.isPaid" fieldName="isPaidField{{rowIndex}}"
                name="isPaid{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="PBJ" field="payCode.isPbj" [filterable]="true" width="80"
          slxKendoGridColumnHiddenState media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.isPbj" aria-hidden="true" class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [ngModel]="dataItem.payCode.isPbj"
                (ngModelChange)="onItemFieldChange(dataItem, 'isPbj', $event)"
                [readonly]="!state.canEditPBJFlag || !dataItem.isCodeApplicableForPbj"
                fieldName="isPbjField{{rowIndex}}" name="isPbj{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [readonly]="!dataItem.isCodeApplicableForPbj" [(ngModel)]="dataItem.payCode.isPbj"
                fieldName="isPbjField{{rowIndex}}" name="isPbj{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="ACA" field="payCode.isAca" [filterable]="true" width="80"
          slxKendoGridColumnHiddenState media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.isAca" aria-hidden="true" class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [(ngModel)]="dataItem.payCode.isAca"
                (ngModelChange)="onItemFieldChange(dataItem, 'isAca', $event)" [readonly]="!canEditAcaCheck(dataItem)"
                fieldName="isAcaField{{rowIndex}}" name="isAca{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [(ngModel)]="dataItem.payCode.isAca" fieldName="isAcaField{{rowIndex}}"
                name="isAca{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Timesheets" field="payCode.useInTimesheets" slxKendoGridColumnHiddenState
          [filterable]="true" width="110" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.useInTimesheets" aria-hidden="true"
                class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [(ngModel)]="dataItem.payCode.useInTimesheets"
                (ngModelChange)="onItemFieldChange(dataItem, 'useInTimesheets', $event)"
                [readonly]="!state.canEditUseInTimesheets" fieldName="useInTimesheetsField{{rowIndex}}"
                name="useInTimesheets{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [(ngModel)]="dataItem.payCode.useInTimesheets"
                fieldName="useInTimesheetsField{{rowIndex}}" name="useInTimesheets{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Is Exception" field="payCode.isException" slxKendoGridColumnHiddenState
          [filterable]="true" width="110" media="(min-width: 450px)">
          <ng-template kendoGridHeaderTemplate>
            <span class="left-align" title="Is Exception">Is Exception</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.isException" aria-hidden="true"
                class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [(ngModel)]="dataItem.payCode.isException"
                (ngModelChange)="onItemFieldChange(dataItem, 'isException', $event)"
                [readonly]="!state.canEditExceptionFlag" fieldName="isExceptionField{{rowIndex}}"
                name="isException{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [(ngModel)]="dataItem.payCode.isException" fieldName="isExceptionField{{rowIndex}}"
                name="isException{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Used In Accrual Calc" field="payCode.isUsedInAccrualCalc" [filterable]="true"
          width="150" media="(min-width: 450px)" slxKendoGridColumnHiddenState>
          <ng-template kendoGridHeaderTemplate>
            <span class="left-align" title="Checked Pay Codes are used to calculate hours worked when Accruals Policy is
            using 'Hours' in the calculation formula">Used In Accrual Calc</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-content">
              <i *ngIf="!state.bulkEditMode && dataItem.payCode.isUsedInAccrualCalc" aria-hidden="true"
                class="fas fa-check"></i>
              <slx-checkbox-button *ngIf="state.bulkEditMode" [(ngModel)]="dataItem.payCode.isUsedInAccrualCalc"
                (ngModelChange)="onItemFieldChange(dataItem, 'isUsedInAccrualCalc', $event)"
                [readonly]="!state.canEditPaycodestoAccrualMappingFlag" fieldName="isUsedInAccrualCalcField{{rowIndex}}"
                name="isUsedInAccrualCalc{{rowIndex}}"></slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="cell-content">
              <slx-checkbox-button [(ngModel)]="dataItem.payCode.isUsedInAccrualCalc"
                fieldName="isUsedInAccrualCalcField{{rowIndex}}" name="isUsedInAccrualCalc{{rowIndex}}">
              </slx-checkbox-button>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <!-- Accrual Policies -->
        <kendo-grid-column title="Accrual Policies" field="payCode.accrualPolicyNames" slxKendoGridColumnHiddenState
          [filterable]="true" width="350" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div *ngIf="!state.bulkEditMode">
              <div class="ap-tooltip" title="{{ dataItem.payCode.accrualPolicyNames }}">
                {{ dataItem.payCode.accrualPolicyNames }}</div>
            </div>
            <div
              *ngIf="state.bulkEditMode && state.canEditPaycodestoAccrualMappingFlag && dataItem.payCode.isUsedInAccrualCalc"
              class="element width-40 auto-height">
              <slx-multiselect [options]="accrualPoliciesLookup" [(ngModel)]="dataItem.payCode.accrualPolicyList"
                (ngModelChange)="onChangeMultiselectValue(dataItem, $event)" titleField="policyName"
                valueField="policyName" [valuePrimitive]="false" placeholder="All Policies"
                [externalPlaceholder]="false" name="baccrualPoliciesLookupInput{{rowIndex}}" label="Policies">
              </slx-multiselect>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-multiselect [options]="accrualPoliciesLookup" [(ngModel)]="dataItem.payCode.accrualPolicyList"
              (ngModelChange)="onChangeMultiselectValue(dataItem, $event)" titleField="policyName"
              valueField="policyName" [valuePrimitive]="false" placeholder="Accrual Policies"
              [externalPlaceholder]="false" name="baccrualPoliciesLookupInput{{rowIndex}}" label="Policies">
            </slx-multiselect>
          </ng-template>
        </kendo-grid-column>

        <!-- Deduct From -->
        <kendo-grid-column title="Deduct From" field="payCode.accrualType" slxKendoGridColumnHiddenState
          [filterable]="true" width="200" media="(min-width: 450px)">

          <ng-template kendoGridHeaderTemplate>
            <span class="left-align" title="When used, this paycode will deduct from the accrual type selected">
              Deduct
              From
            </span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <span *ngIf="!state.bulkEditMode">{{ dataItem.payCode.accrualType }}</span>
            <span
              *ngIf="state.bulkEditMode && state.canEditPaycodestoAccrualMappingFlag && canDeductFrom(dataItem)">
              <slx-dropdown-list [options]="accrualTypesLookup" valueField="accrualTypeName"
                titleField="accrualTypeName" [(ngModel)]="dataItem.payCode.accrualTypes"
                (ngModelChange)="onChangeAccrualType(dataItem, $event)" name="baccrualType{{rowIndex}}">
              </slx-dropdown-list>
            </span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <span
              *ngIf="state.bulkEditMode && state.canEditPaycodestoAccrualMappingFlag && canDeductFrom(dataItem)">
              <slx-dropdown-list [options]="accrualTypesLookup" valueField="accrualTypeName"
                titleField="accrualTypeName" [(ngModel)]="dataItem.payCode.accrualTypes"
                (ngModelChange)="onChangeAccrualType(dataItem, $event)" name="baccrualType{{rowIndex}}">
              </slx-dropdown-list>
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Pay Differential" field="payCode.payDifferential" slxKendoGridColumnHiddenState
          [filterable]="true" width="150" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.payCode.payDifferential }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.payDifferential"
                [required]="true" [placeholder]="" name="payDifferentialInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Amount" field="payCode.amount" [filterable]="true" slxKendoGridColumnHiddenState
          width="100" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{ dataItem.payCode.amount }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-number-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.amount" [required]="true"
                [placeholder]="" name="amountInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Group" field="payCode.group" slxKendoGridColumnHiddenState [filterable]="true"
          width="100" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.payCode.group }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.group" [required]="true"
                [placeholder]="" name="groupInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Allocation Type" field="payCode.allocationType" slxKendoGridColumnHiddenState
          [filterable]="true" width="150" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.payCode.allocationType }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <div class="cell-content">
                <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.allocationType"
                  [required]="true" [placeholder]="" name="allocationTypeInput{{rowIndex}}">
                <span errorMessage for="required"></span>
              </div>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="{{ isNextgenPayrollEnabled ? 'Payroll Pay Code' : 'Interface Indicator'}}" field="payCode.interfaceIndicator" slxKendoGridColumnHiddenState
          [filterable]="true" width="160" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.payCode.interfaceIndicator }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.payCode.interfaceIndicator"
                [required]="true" [placeholder]="" name="interfaceIndicatorInput{{rowIndex}}">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <!-- Export PDF and Excel -->
        <kendo-grid-excel fileName="PayCode.xlsx">
        </kendo-grid-excel>
        <kendo-grid-pdf fileName="PayCode.pdf" [allPages]="true">
          <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>

          <kendo-grid-column *ngIf="isExport('payCode.description')" field="payCode.description" title="Name"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.department')" field="payCode.department" title="Department"></kendo-grid-column>
          <kendo-grid-column field="payCode.color" title="Color">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="col color-cell color-border" [style.background-color]="dataItem.payCode.color | DecHex"></div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.isPaid')" field="payCode.isPaid" title="Paid">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.isPaid" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.isPbj')" field="payCode.isPbj" title="PBJ">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.isPbj" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.isAca')" field="payCode.isAca" title="ACA">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.isAca" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.useInTimesheets')" field="payCode.useInTimesheets" title="Timsheets">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.useInTimesheets" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.isException')" field="payCode.isException" title="Is Exception">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.isException" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.isUsedInAccrualCalc')" field="payCode.isUsedInAccrualCalc" title="Used In Accrual Calc">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="cell-content">
                <i *ngIf="dataItem.payCode.isUsedInAccrualCalc" aria-hidden="true" class="fas fa-check"></i>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.accrualType')" field="payCode.accrualType" title="Deduct From"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.payDifferential')" field="payCode.payDifferential" title="Pay Differential"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.amount')" field="payCode.amount" title="Amount"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.group')" field="payCode.group" title="Group"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.allocationType')" field="payCode.allocationType" title="Allocation Type"></kendo-grid-column>
          <kendo-grid-column *ngIf="isExport('payCode.interfaceIndicator')" field="payCode.interfaceIndicator" title="{{ isNextgenPayrollEnabled ? 'Payroll Pay Code' : 'Interface Indicator'}}"></kendo-grid-column>
        </kendo-grid-pdf>

        <kendo-grid-messages filterMenuTitle="Filter">
        </kendo-grid-messages>  
      </kendo-grid>
    </form>
  </div>
</slx-spinner>
