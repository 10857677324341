import { Component, Input, Output, OnInit, OnDestroy, ElementRef, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GroupResult, orderBy, groupBy, process, State, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  RowArgs,
  CellClickEvent,

  RowClassArgs
} from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { EmployeeColumnsDefinition, Employee, EmployeeFieldDefinition } from '../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { EmployeeListMapService, EmployeeListService } from '../../services/index';
import { employeeListConfig, IEmployeeListConfig } from '../../employee-list.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ExcelExportData, Workbook, WorkbookSheet, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { EmployeeListCommonService } from '../../services/employee-list/employee-list-common.service';
import { LocalStorageService } from '../../../../core/services/local-storage/local-storage.service';
import { NavigationStart, Router } from '@angular/router';
@Component({
  moduleId: module.id,
  selector: 'slx-employee-grid',
  templateUrl: 'employee-grid.component.html',
  styleUrls: ['employee-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeGridComponent implements OnInit, OnDestroy {
  @Input()
  public isMultiselect: boolean;
  @Input()
  public isGroupable: boolean = true;
  @Input()
  public isPaging: boolean =true;

  @Input()
  public set fieldDefinition(fieldDefinition: EmployeeColumnsDefinition) {
    this.currentFieldDefinition = fieldDefinition;
    this.updateFields();
    this.useNameRestriction = fieldDefinition && (<any>fieldDefinition).listName === 'Physicals';
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @Input()
  public set employees(employees: Employee[]) {
    this.currentEmployees = employees || [];
    this.updateEmployeeData();
    this.getAgencyCertificateDisplayName();
    this.generateFilters(employees);
    this.refreshGrid();
  }
  @Input()
  public columnsGroupName: string = 'EmployeeList';

  @Output()
  public onEmployeesSelect: EventEmitter<Employee[]>;

  @Output()
  public onEmployeeOpen: EventEmitter<Employee>;

  @Output()
  public onFiltered: EventEmitter<Employee[]>;

  @Input()
  public isColumnLocked: boolean;

  @Input()
  public exportFileName: string;

  @Input()
  public isOptInStatus: boolean = false;

  @Input()
  public isOptInEmail: boolean = false;

  public typeFilters: any[];
  @Input()
  public pageSize: number = 50;
  public appConfig: IApplicationConfig;
  public employeeListConfig: IEmployeeListConfig;
  public isAllSelected: boolean;
  public gridState: KendoGridStateHelper<Employee>;
  public currentFieldDefinition: EmployeeColumnsDefinition;
  public useNameRestriction: boolean = false;
  private currentEmployees: Employee[];
  private selectedEmployees: string[];
  private empIdKey: string;
  private isSuppres: boolean;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSelectionSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef,
    private employeeListCommonService: EmployeeListCommonService,
    private localStorage: LocalStorageService,
    private router: Router) {
    this.onEmployeesSelect = new EventEmitter<Employee[]>();
    this.onEmployeeOpen = new EventEmitter<Employee>();
    this.onFiltered = new EventEmitter<Employee[]>();
    this.gridState = new KendoGridStateHelper<Employee>();
    this.gridState.state.skip = 0;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.gridState.state.take = this.pageSize;
      this.refreshGrid();
    });
    this.gridSelectionSubscription = this.gridState.onSelectionChanged.subscribe((selectedItems: Employee[]): void => {
      this.onEmployeesSelect.emit(selectedItems);
    });
    this.appConfig = appConfig;
    this.employeeListConfig = employeeListConfig;
    this.selectedEmployees = [];
    this.empIdKey = employeeListConfig.employeeIdentifierName;
  }

  public getfilteredEmployeeList():any {
    const employees = process(this.currentEmployees, { filter: this.gridState.state.filter }).data;
    return employees;
  }
  public ngOnInit(): void {
    // this.refreshGrid();
  }

  public isColumnSuppressed(fieldata) {
    return !this.employeeListCommonService.isSpecColumn(fieldata);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public sortByNameInAscending() {
    this.gridState.state.sort = [
      { field: 'EmpName', dir: 'asc' }
    ];
  }

  public refreshGrid(): void {
    if (!this.currentEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.currentEmployees, this.gridState.state);
  }

  public getFilter(type: string): string {
    if (type === 'int') return 'numeric';
    if (type === 'boolean') return 'boolean';
    if (type === 'date') return 'date';
    if (type === 'datetime') return 'date';
    return null;
  }


  public setEmployeesToSelect(employeeIds?: string[]): void {
    this.selectedEmployees.length = 0;
    this.selectedEmployees.push(...employeeIds);
    const selectedEmployees: Employee[] = this.extractItems(this.selectedEmployees);
    _.forEach(selectedEmployees, (emp: Employee) => { emp.isSelected = true; });
  }

  public onToggleAllSelected(): void {
    const employees = process(this.currentEmployees, { filter: this.gridState.state.filter }).data;
    const selectedEmployees: Employee[] = _.filter(employees, (employee: Employee) => {
      employee.isSelected = this.isAllSelected && employee.Selectable;
      return (employee.isSelected as boolean);
    });
    this.selectedEmployees.length = 0;
    this.selectedEmployees.push(...this.extractIds(selectedEmployees));
    this.onEmployeesSelect.emit(selectedEmployees);
    }

  public selectionChange(): void {
    const selectedEmployees: Employee[] = _.filter(this.extractEmployees(), (emp: Employee) => (emp.isSelected as boolean));
    this.selectedEmployees.length = 0;
    this.selectedEmployees.push(...this.extractIds(selectedEmployees));
    this.setCheckBoxState();
    this.onEmployeesSelect.emit(selectedEmployees);
  }
  public setCheckBoxState() {
    const disabledEmployee: Employee[] = _.filter(this.extractEmployees(), (employee: Employee) => (!employee.Selectable));
    if ((this.selectedEmployees.length + disabledEmployee.length) !== this.extractEmployees().length) {
      this.isAllSelected = false;
    } else {
      this.isAllSelected = true;
    }
  }
  public onCellClick(clickEvent: CellClickEvent): void {
    const extractedEmps: Employee[] = this.extractEmployees();
    const employee = _.first(extractedEmps.filter(x=>x.EmpId === clickEvent.dataItem.EmpId)) as Employee & { Selectable: boolean, isSelected: boolean };
    if (_.isObject(employee) && employee.Selectable) {
      if (employee.isSelected) {
        const index: number = _.indexOf(this.selectedEmployees, this.empId(employee));
        this.selectedEmployees.splice(index, 1);
        employee.isSelected = false;
      } else {
        if (!this.isMultiselect) {
          this.selectedEmployees.length = 0;
          _.forEach(extractedEmps, (emp: Employee) => { emp.isSelected = false; });
        }
        employee.isSelected = true;
        this.selectedEmployees.push(this.empId(employee));
      }
      this.onEmployeesSelect.emit(this.extractItems(this.selectedEmployees));
      this.setCheckBoxState();
    }
  }

  public isRowSelected(): (row: RowArgs) => boolean {
    return (row: RowArgs) => _.indexOf(this.selectedEmployees, this.empId(row.dataItem)) !== -1;
  }

  public refreshColumns(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private extractEmployees(): Employee[] {
    let extractedEmp: Employee[] = [];
    _.forEach(this.currentEmployees, (obj: any) => {
      if (obj.hasOwnProperty('items')) {
        extractedEmp = extractedEmp.concat(obj.items);
      } else {
        extractedEmp.push(obj);
      }
    });
    return extractedEmp;
  }

  private empId(employee: Employee): string {
    return employee[this.empIdKey];
  }

  private extractIds(employees: Employee[]): string[] {
    return _.map(employees, (emp) => this.empId(emp));
  }

  private extractItems(empIds: string[]): Employee[] {
    return _.filter(this.extractEmployees(), (emp: Employee) => _.includes(empIds, this.empId(emp)));
  }

  public exportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public exportToPdf(): void {
    let selection = this.selectedEmployees;
    _.forEach(this.selectedEmployees, (emp)=>{
      this.selectedEmployees.pop();
    })
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.grid.saveAsPDF();
    _.forEach(selection, (emp)=>{
      this.selectedEmployees.push(emp);
    })
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public retriveAllPages(): () => ExcelExportData {
    return (): ExcelExportData => (
      {
        data: process(this.currentEmployees, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
      }
    ) as ExcelExportData;
  }

  public onExcelExport(e: { workbook: Workbook }): void {
    const sheets: WorkbookSheet = _.head(_.get(e, 'workbook.sheets'));
    _.forEach(sheets.rows, (row: WorkbookSheetRow) => {
      if (row.type === 'data') {
        _.forEach(row.cells, (cell) => {
          if (_.isBoolean(cell.value)) {
            cell.value = cell.value ? 'Yes' : 'No';
          }
        });
      }
    });
  }


  public notBlank(dataItem: Employee, fieldName: string): boolean {
    let showDollar = false;

    if(fieldName === 'Employee Contribution') {
      if(!_.isNull(dataItem.BenefitEmployeeContributionAmt)) {
        showDollar = true;
      }
    } else if(fieldName === 'Employer Contribution') {
      if(!_.isNull(dataItem.BenefitEmployerContributionAmt)) {
        showDollar = true;
      }
    } else if(fieldName === 'Cost') {
      if(!_.isNull(dataItem.BenefitCost)) {
        showDollar = true;
      }
    }

    return showDollar;
  }

  private generateFilters(employees: Employee[]): void {
    if (employees) {
      this.typeFilters = this.optInStatusFilters();
    }
  }
  
  private optInStatusFilters(): any {
    return [
      {
        text: 'Opted Out',
        value: 'Opted Out'
      },
      {
        text: 'Opted In',
        value: 'Opted In'
      },
      {
        text: 'Not Responded',
        value: 'Not Responded to the Opt In Request'
      }
    ]
  }

  public getFormat(format: string): string {
    return _.size(format) > 0 ? format : '';
  }

  public updateEmployeeData(): void {
    this.currentEmployees.map((each) => {
      each['Covid_19VaccinationReceived_CBA_Vac_1'] = each['Covid-19VaccinationReceived@CBA-Vac.1'];
      each['Covid_19VaccinationReceived_CBA_Vac_2'] = each['Covid-19VaccinationReceived@CBA-Vac.2'];
      each['Covid_19VaccinationReceivedOutsideofCBA_Vac_2'] = each['Covid-19VaccinationReceivedOutsideofCBA-Vac.2'];
      each['Covid_19VaccinationReceivedOutsideofCBA_Vac_1'] = each['Covid-19VaccinationReceivedOutsideofCBA-Vac.1'];
      each['Covid_19VaccinationRefused'] = each['Covid-19VaccinationRefused'];
      each['Covid_19Status'] = each['Covid-19Status'];
      each['Covid_19ExamDate'] = each['Covid-19ExamDate'];
      each['FluVaccineReceived_CBK'] = each['FluVaccineReceived@CBK'];
      each['ChestX_RayStatus'] = each['ChestX-RayStatus'];
      each['ChestX_RayExamDate'] = each['ChestX-RayExamDate'];
    });
  }

  public updateFields(): void {
    this.currentFieldDefinition.fields.map((each: EmployeeFieldDefinition) => {
      each.fieldName = this.renameFieldName(each.fieldName);
    });
  }

  private renameFieldName(fieldName: string): string {
    switch (fieldName) {
      case 'Covid-19VaccinationReceived@CBA-Vac.1':
        return 'Covid_19VaccinationReceived_CBA_Vac_1';
      case 'Covid-19VaccinationReceived@CBA-Vac.2':
        return 'Covid_19VaccinationReceived_CBA_Vac_2';
      case 'Covid-19VaccinationReceivedOutsideofCBA-Vac.2':
        return 'Covid_19VaccinationReceivedOutsideofCBA_Vac_2';
      case 'Covid-19VaccinationReceivedOutsideofCBA-Vac.1':
        return 'Covid_19VaccinationReceivedOutsideofCBA_Vac_1';
      case 'Covid-19VaccinationRefused':
        return 'Covid_19VaccinationRefused';
      case 'Covid-19Status':
        return 'Covid_19Status';
      case 'Covid-19ExamDate':
        return 'Covid_19ExamDate';
      case 'FluVaccineReceived@CBK':
        return 'FluVaccineReceived_CBK';
      case 'ChestX-RayStatus':
        return 'ChestX_RayStatus';
      case 'ChestX-RayExamDate':
        return 'ChestX_RayExamDate';
    }
    return fieldName;
  }
  public getAgencyCertificateDisplayName() : void {
    this.currentEmployees.forEach(element => {
     
      if(element.Preferred != null || element.DNR!=null) {
       element.Preferred = element.Preferred == "True" ? 'Yes': 'No';
       element.DNR = element.DNR == "True" ? 'Yes': 'No';
      }
    });
  }

  dataStateChange(state: DataStateChangeEvent): void{
    let gridState: DataStateChangeEvent = this.localStorage.get('GridSettings');
    let hitFromEmpSection = localStorage.getItem('hitFromEmpSection');
    let backButtonClick = this.localStorage.get('backButtonClicked');
    if(gridState && hitFromEmpSection === 'yes'){
      this.convertToDate(gridState);
      this.updateGridState(gridState);
      this.localStorage.remove('hitFromEmpSection');
    }
    else if(gridState && backButtonClick === 'yes'){
      this.convertToDate(gridState);
      this.updateGridState(gridState);
      this.localStorage.remove('backButtonClicked');
    }
    else{ 
      this.localStorage.set('GridSettings', state);
      this.updateGridState(state);
      this.router.events.subscribe((event: NavigationStart) => {
        if(event.navigationTrigger == "popstate"){ 
          let currentUrls: string[], splitUrls: string[], currentUrl: string
            , empId = "d001"; //To compare URL against any employee, replaced employee id in URL with this value for comparing
          if(this.router.url.startsWith("/apps/common/employee/") && this.router.url.includes("employee_sections")){
            currentUrls = this.router.url.split('?');
            if(currentUrls[0]){
              splitUrls = currentUrls[0].split('/');
              if(splitUrls.length > 5){
                splitUrls[4] = empId;
              }
            }
            currentUrl = splitUrls.join('/');
            if(currentUrl === `/apps/common/employee/${empId}/employee_sections`){
              this.localStorage.set('backButtonClicked', 'yes');
            }
          }
        }
      })
    }
  }
  updateGridState(state: DataStateChangeEvent){
    this.gridState.state = state;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }
  convertToDate(state: DataStateChangeEvent){
    if(state.filter){
      state.filter.filters.forEach( (item:CompositeFilterDescriptor) => {
        item.filters.forEach((item: FilterDescriptor) => {
          if(this.isIsoDate(item.value))
            item["value"] = new Date(item.value);
        })
      })
    }
  }
  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString()===str; // valid date 
  }
}
