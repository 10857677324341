import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { unsubscribe } from '../../../../core/decorators/index';

import { MasterScheduleManagementService, MasterScheduleQuickEditService, ScheduleEntryApiService } from '../../../services/index';
import { ScheduleEntryEditItem, ScheduleQuickEditConfiguration } from '../../../models/index';
import { OrgLevelShiftTemplate, Department, LookupType, Lookup, IScheduleActions } from '../../../../organization/models/index';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { LookupService } from '../../../../organization/services/index';
import { ConfirmDialog2Component, ConfirmOptions } from '../../../../common';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-quick-edit',
  templateUrl: 'master-schedule-quick-edit.component.html',
  styleUrls: ['master-schedule-quick-edit.component.scss']
})
export class MasterScheduleQuickEditComponent implements OnInit, OnChanges {
  @Input()
  public orgLevelId: number;
  @unsubscribe()
  private actionsSubscription: Subscription;
  public showChanges: boolean = false;
  public isQuickEditStarted: boolean = false;
  approvedPayPeriod: boolean;
  actions: IScheduleActions;
  public get hasChanges(): boolean {
    return this.items && this.items.length > 0;
  }
  public items: ScheduleEntryEditItem[];

  public isAllFieldsPopulated: boolean;
  public appConfig: IApplicationConfig;
  public disablepopup:boolean = true;
  public configuration: ScheduleQuickEditConfiguration;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private department: Department;
  public isModifyPayPeriodApproved: boolean;

  constructor(private masterScheduleQuickEditService: MasterScheduleQuickEditService, 
    private lookupService: LookupService,
    private modalService: ModalService,
    private managementService: MasterScheduleManagementService,
    private appSettingsManageService: AppSettingsManageService,
    private ScheduleEntryApiService: ScheduleEntryApiService
      ) {
    this.configuration = new ScheduleQuickEditConfiguration();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.masterScheduleQuickEditService.quickEditListChanged$.subscribe((items: ScheduleEntryEditItem[]) => {
      this.items = items;
    });
    this.getSettings();
    this.actionsSubscription = this.managementService.actions$.subscribe((data: IScheduleActions) => {
      this.actions = data;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['orgLevelId'] && this.orgLevelId) {
      this.department = null;
      this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.orgLevelId })
        .then((res: Lookup) => {
          this.department = _.first(res.items);
        });
    }
  }

  public undoEdit(item: ScheduleEntryEditItem): void {
    this.masterScheduleQuickEditService.undoEditCommand([item]);
  }

  public showChangesClick(): void {
    this.showChanges = true;
  }

  public hideChangesClick(): void {
    this.showChanges = false;
  }

  public startQuickEdit(): void {
    this.isQuickEditStarted = true;
    this.masterScheduleQuickEditService.startQuickEdit();
  }
  public stopQuickEdit(): void {
    this.disablepopup = false;
    let req = [];
    for (var val of this.items) {
      var enddate = moment(val.date).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
      var finaldate = moment(enddate).format("YYYY-MM-DD HH:mm:ss");
      let obj = {
        EmpId: val.employeeId,
        StartDate: moment(val.date).format("YYYY-MM-DD HH:mm:ss"),
        EndDate: finaldate
      };
      req.push(obj);
    }
    this.ScheduleEntryApiService.getApprovedPayPeriodForQuickEdit(req).then((data: any) => {
      this.approvedPayPeriod = data;
      if (this.isModifyPayPeriodApproved && this.approvedPayPeriod == true) {
        const message = this.actions.canEditForApprovedPayPeriod ?
        `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the 
        employee, are you sure you want to continue?`: `You do not have permissions to modify a 
        schedule in an approved pay period`;        
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.actions.canEditForApprovedPayPeriod ? true : false;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.actions.canEditForApprovedPayPeriod ? 'Cancel':'Ok';
        ConfirmDialog2Component.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.stopQuickEdits();
            }
            else {
              this.disablepopup = true;
            }
          },popupOptions)
      }
      else {
        this.stopQuickEdits();
        this.disablepopup = true;
      }

    })
  };

  public stopQuickEdits(){
    this.masterScheduleQuickEditService.stopQuickEdit();
    this.isQuickEditStarted = false;
  }
  public async onReplaceAlwaysChanged(): Promise<any> {
    await this.masterScheduleQuickEditService.setEditConfiguration(this.configuration, !this.isAllFieldsPopulated);
  }
  public async configurationChanged(shiftTemplate: OrgLevelShiftTemplate): Promise<any> {
    if (shiftTemplate.shift && shiftTemplate.shift.id &&
      (shiftTemplate.unit && shiftTemplate.unit.id
        || shiftTemplate.absence && shiftTemplate.absence.code
      )
    ) {
      this.isAllFieldsPopulated = true;
    } else {
      this.isAllFieldsPopulated = false;
    }
    this.configuration.shift = shiftTemplate.shift;
    this.configuration.unit = shiftTemplate.unit;
    this.configuration.constraint = shiftTemplate.constraint;
    this.configuration.absence = shiftTemplate.absence;
    this.configuration.department = this.department;
    await this.masterScheduleQuickEditService.setEditConfiguration(this.configuration, !this.isAllFieldsPopulated);
  }
  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }
}
