<slx-spinner [show]="isLoading">
  <div id="mapping-modal-body" class="modal-body benefit-provider">

    <form class="row-form row-item">
      <div>
        <span *ngIf="isPreviewState" class="slx-title__text">Preview Pay Raises</span>
      </div>

      <div  style="display: inline-block;">
        <div class="toolbar">
          <div class="plable">
            <p class="slx-benefit-box__value flex__grow field__label">Type: </p>
          </div>
          <div class="element plable" >

            <slx-radio-button [readonly]="isPreviewState" class="p-left-0 plabel" name="FlatRate" fieldName="FlatRate"
              [option]="true" [(ngModel)]="type" caption="Flat Rate" (ngModelChange)="onisincrementTypeChanged($event)">
            </slx-radio-button>
            <slx-radio-button [readonly]="isPreviewState" class="p-left-0 plabel" name="Percentage"
              fieldName="Percentage" [option]="false" [(ngModel)]="type" caption="Percentage"
              (ngModelChange)="onisincrementTypeChanged($event)">
            </slx-radio-button>
          </div>
          <div class="plable">
            <p class="slx-benefit-box__value flex__grow field__label">Increment:</p>
          </div>
           
          <div class="column element">
              <slx-number  [readonly]="isPreviewState"  [(ngModel)]="increment" [required]="'true'"  class="slx-wide slx-input-number"
              name="increment" [decimals]="incrementdecimal" [min]="0.00" [max]="9999.9999"
              [format]="incrementFormat">
              </slx-number  >
          </div>
          <div *ngIf="isIncreaseReasonsEnabled" class="plable">
            <p class="slx-benefit-box__value flex__grow ">Increase Reason:</p>
          </div>
          <div *ngIf="isIncreaseReasonsEnabled" class="slx-benefit-box__control flex align-items__center element">
            <p class="slx-benefit-box__value flex__grow field__label">
              <slx-dropdown-list   class="slx-wide"
              [lookup]="{lookupType: 'increaseReason'}" 
              [defaultItem]="defaultItem"
              [readonly]="isPreviewState" 
              [(ngModel)]="increaseReason" required="true" name="increaseReason"
              ></slx-dropdown-list>
            </p>
          </div>
          <div class="plable ">
            <p class="slx-benefit-box__value flex__grow ">Effective Date:</p>
          </div>
          <div class="element">
                <slx-date-picker-ngx [readonly]="isPreviewState" slx-input [(ngModel)]="effectiveDate" [required]="true"
                  name="effectiveDate" placeholder="" (ngModelChange)="onChangeEffectiveDate($event)">
                </slx-date-picker-ngx>
          </div>
        </div>
      </div>

    </form>

    <div class="mapping-grid" *ngIf="!isPreviewState && pageSize > 0">
      <slx-employee-grid  *ngIf="  pageSize > 0"  [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true"
        [isGroupable] = "false" [isPaging] = "false" [pageSize] = "pageSize" (onEmployeesSelect)="employeesSelect($event)">
      </slx-employee-grid>
    </div>
  <div>
      <div class="mapping-grid" *ngIf="isPreviewState">
        <kendo-grid #kendoGrid class="emp-grid slx-blue-grid slx-transparent-selection"
          slxKendoGridState="Mass_PayRate_Adustment" [data]="previewgridState.view" [filterable]="'menu'"
          [filter]="previewgridState.state.filter" [skip]="previewgridState.state.skip" [sortable]="true"
          [sort]="previewgridState.state.sort" [height]="'100%'"
          (dataStateChange)="previewgridState.dataStateChange($event)"
          (stateRestored)="previewgridState.dataStateChange($event)">

          <kendo-grid-column title="Employee" width="250" field="empName" filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employee</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.empName }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Department" width="250" field="department" filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Department</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.department }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Position" width="150" field="position" filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Position</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.position }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="effectiveDate" width="150" field="effectiveDate" filter="date">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Effective Date</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.effectiveDate }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="hourlyRate" width="200" field="hourlyRate" filter="numeric">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Rate (Current)</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.hourlyRate }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="increment" width="120" field="increment" filter="numeric">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Increment</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.increment }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Hourly Rate (New)" width="150" field="revisedHourlyRate" filter="numeric">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Rate (New)</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.revisedHourlyRate }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-messages filterMenuTitle="Filter">
          </kendo-grid-messages>  
        </kendo-grid>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn red" (click)="cancel()">
      <i class="fa fa-times" aria-hidden="true"></i>
      <span class="btn-text__padding">Cancel</span>
    </button>
    <button class="btn blue" [disabled]="!canPreview" *ngIf="!isPreviewState" (click)="PreviewDialog()">
      <span class="btn-text__padding"><i class="fa fa-eye slx-button__icon" aria-hidden="true"></i>Preview</span>
    </button>
    <button class="btn green" [disabled]="!canPreview" *ngIf="isPreviewState" (click)="PreviewDialog()">
      <span class="btn-text__padding"><i class="fa fa-save slx-button__icon" aria-hidden="true"></i>Save</span>
    </button>
  </div>
</slx-spinner>