import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ScheduleDailyDifferences } from '../../../models/index';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-schedule-daily-differences-grid',
  templateUrl: 'schedule-daily-differences-grid.component.html',
  styleUrls: ['schedule-daily-differences-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleDailyDifferencesGridComponent implements OnInit, OnDestroy {
  @Input()
  public set records(value: ScheduleDailyDifferences[]) {
    this.m_records = value;
    this.refreshGrid();
  }
  public dataLoaded: boolean = false;
  public gridState: KendoGridStateHelper<ScheduleDailyDifferences>;
  public appConfig: IApplicationConfig;
  public aggregates: any = [
    { field: 'requiredCount', aggregate: 'sum' },
    { field: 'requiredHours', aggregate: 'sum' },
    { field: 'scheduledCount', aggregate: 'sum' },
    { field: 'scheduledHours', aggregate: 'sum' },
    { field: 'differencesCount', aggregate: 'sum' },
    { field: 'differencesHours', aggregate: 'sum' }
  ];
  public total: any = {};

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  private m_records: ScheduleDailyDifferences[];

  constructor(private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<ScheduleDailyDifferences>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {
    if (!this.m_records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);
    this.gridState.view = process(this.m_records, this.gridState.state);
    this.total = aggregateBy(this.m_records, this.aggregates);
    this.dataLoaded = true;
  }
}
