import { Component, OnInit } from '@angular/core';
import { ApplicationStateBusService } from '../../../organization';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { unsubscribe } from '../../../core/decorators/index';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'slx-header-timezones-list',
  templateUrl: './header-timezones-list.component.html',
  styleUrls: ['./header-timezones-list.component.scss']
})

export class HeaderTimezonesListComponent implements OnInit {

  public organizations: OrgLevel[];
  public organizationTimes: { [key: number]: string } = {};

  @unsubscribe()
  public timeSubscription: Subscription;
  @unsubscribe()
  private stateBusSubscription: Subscription;
  
  constructor(private stateBus: ApplicationStateBusService) { }

  ngOnInit() {
    this.stateBusSubscription = this.stateBus.orgLevelsLoaded$.subscribe((orgLevelTree: OrgLevel[]) => {
      this.organizations = this.getOrganizationsFromTree(orgLevelTree);

      this.timeSubscription = timer(1000, 1000).subscribe(() => {
        this.updateTime();
      });
    })
  }

  public getOrganizationsFromTree(orgLevelTree: OrgLevel[]): OrgLevel[] {
    let organizations: OrgLevel[] = [];

    orgLevelTree.forEach(orgLevel => {
      organizations = organizations.concat(this.getAllOrganizations(orgLevel));
    });

    organizations.forEach(organization => {
      this.organizationTimes[organization.id] = this.getTimeForTimezone(organization.location.timeZone);
    });

    return organizations;
  }

  public getAllOrganizations(orgLevel: OrgLevel): OrgLevel[] {
    let organizations: OrgLevel[] = [];

    if (orgLevel.type === OrgLevelType.organization) {
      organizations.push(orgLevel);
    }

    if (orgLevel.childs && Array.isArray(orgLevel.childs) && orgLevel.childs.length > 0) {
      orgLevel.childs.forEach(child => {
        organizations = organizations.concat(this.getAllOrganizations(child));
      });
    }

    return organizations;
  }

  public updateTime(): void {
    this.organizations.forEach(organization => {
      this.organizationTimes[organization.id] = this.getTimeForTimezone(organization.location.timeZone);
    });
  }

  public getTimeForTimezone(timezone: string): string {
    if(timezone!=null){
      return moment.tz(timezone).format("hh:mm A") + " " + moment.tz(timezone).format("zz");
    }else{
      return moment.tz("America/Chicago").format("hh:mm A") + " " + moment.tz("America/Chicago").format("zz");
    }
  }
}
