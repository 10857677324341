import {
  MasterScheduleFilters,
  MasterScheduleLookupFilters,
} from './../../../models/master-schedule/master-schedule-filters';
import * as moment from 'moment';
import * as _ from 'lodash';

import { RowNode } from 'ag-grid-community';
import {
  isMasterScheduleEntryRow,
  isMasterScheduleSubtotalRow,
  isMasterScheduleTotalRow,
  isMasterScheduleEntryCell,
  isMasterScheduleTotalCell,
  IMasterScheduleTotalRow,
  MasterScheduleEntryCell,
  MasterScheduleTotalCell,
  MasterScheduleEntryRow,
  TotalsDescription,
} from '../../../../scheduler/models/index';
import { appConfig } from '../../../../app.config';
import { EmployeesStateSelector } from '../../../store/selectors/index';
import { MasterScheduleCustomGroupHeader } from '../ag-grid-extentions/master-schedule-custom-gh/master-schedule-custom-gh.component';
import { IMasterScheduleGroupingType } from '../../../models/master-schedule/master-schedule-grouping-type';
import {  screenUtils } from './../../../../common/utils/index';


export function totalComparator(value1: any, value2: any, node1: RowNode, node2: RowNode, isInverted: boolean): number {
  if (isMasterScheduleTotalRow(node1.data) && isMasterScheduleTotalRow(node2.data)) {
    return 0;
  }

  if (isMasterScheduleTotalRow(node1.data)) {
    return isInverted ? -1 : 1;
  }

  if (isMasterScheduleTotalRow(node2.data)) {
    return isInverted ? 1 : -1;
  }
  return undefined;
}

export class MasterScheduleGridHelper {
  public columnDefs: any[];
  public weeks: any[] = [];

  public empTypesMap: StringMap<any>;

  public state: {
    startDate: Date;
    endDate: Date;
    numWeeks: number;
    isEmployeeScheduleChangedEvent: boolean;
    cellDisplay: string;
    positionGroupColShown: boolean;
    positionColShown: boolean;
    dateOfHireColShown: boolean;
    dateTerminatedColShown: boolean;
    shiftColShown: boolean;
    sumShiftsPerWeek: boolean;
    avgWeeklyRotationsHrs: boolean;
    displayOrder: string;
    separateSeconaryPositions: boolean;
    pinTotalRows: boolean;
    weeklyTotals: boolean;
    positionSubtotals: boolean;
    dailyTotals: boolean;
    idealSchedule: boolean;
    budgetedPars: boolean;
    openShifts: boolean;
    difference: boolean;
    census: boolean;
    scheduledPpd: boolean;
    staffingRatio: boolean;
    actualHours: boolean;
    actualPpd: boolean;
    viewTotalsFTEs: boolean;
    positionGrouping: IMasterScheduleGroupingType;
    employeeTypeColShown: boolean;
  };

  public getDayDate(colName: string): Date {
    let date = moment(colName.substr(8, 19), appConfig.requestDate).toDate();
    return date;
  }

  public getDayNameFromCell(params: any): string {
    const cell = this.getCell(params);
    if (isMasterScheduleTotalCell(cell)) {
      return null;
    }
    let date = this.getDayDate(params.colDef.field);
    return moment(date).format('dd');
  }

  public getCell(params: any): MasterScheduleEntryCell | MasterScheduleTotalCell {
    let date = this.getDayDate(params.colDef.field);
    let day: string = EmployeesStateSelector.getDateKey(date);
    const cell: MasterScheduleEntryCell | MasterScheduleTotalCell = params.data.cell[day];
    return cell;
  }

  public isNotActiveCell(params: any): boolean {
    if (isMasterScheduleEntryRow(params.data)) {
      const row: MasterScheduleEntryRow = params.data;
      let firstDate: moment.Moment;
      if (!row.hireDate && row.activationDate) {
        firstDate = moment(row.activationDate);
      } else if (row.hireDate && !row.activationDate) {
        firstDate = moment(row.hireDate);
      } else if (!row.hireDate && !row.activationDate) {
        firstDate = undefined;
      } else {
        firstDate = moment.min(moment(row.hireDate), moment(row.activationDate));
      }
      let date = this.getDayDate(params.colDef.field);
      if (moment(date).isAfter(row.dateTerminated) || !firstDate || moment(date).isBefore(firstDate)) {
        return true;
      }
    }
    return false;
  }

  public isDifferentDepartmentScheduled(params: any): boolean {
    if (isMasterScheduleEntryRow(params.data)) {
      const row: MasterScheduleEntryRow = params.data;
      const cell = this.getCell(params);
      if (isMasterScheduleEntryCell(cell)) {
        const entryCell: MasterScheduleEntryCell = cell;
        return entryCell.scheduledInDifferntDepartment;
      }
    }
    return false;
  }
  public isQuickEdited(params: any): boolean {
    if (isMasterScheduleEntryRow(params.data)) {
      const row: MasterScheduleEntryRow = params.data;
      const cell = this.getCell(params);
      if (isMasterScheduleEntryCell(cell)) {
        const entryCell: MasterScheduleEntryCell = cell;
        return entryCell.isInQuickEdit;
      }
    }
    return false;
  }

  public getIcons(): any {
    return {
      menu: '<span class="k-i-filter k-icon"></span>',
    };
  }

  public getCellRender(filters: MasterScheduleFilters): any {
    const filter: MasterScheduleLookupFilters = filters ? filters.filters : null;
    return function (params: any): string {
      if (isMasterScheduleTotalRow(params.data)) {
        if (params.data.name === TotalsDescription.totalBudgetedPars) {
          let html: string = `<span class="ms-badge theme-tooltip totals-text-container">
                            <span class="totals-text">${params.value}</span>
                            <span class="theme-tooltiptext totals-tooltip right">${TotalsDescription.getTooltip(
                              params.data.name
                            )}</span>
                        </span>`;
          if (
            filter &&
            ((filter.employeeType && filter.employeeType.length) ||
              (filter.shiftGroup && filter.shiftGroup.id) ||
              (filter.unit && filter.unit.id))
          ) {
            html = `${html}<div class="ms-badge-container">
            <span class="ms-badge agency-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext budgetedPar-tooltip">Only Position filter are applicable to this total value</span></span>
            </div>`;
          }
          return html;
        }
        return `<span class="theme-tooltip totals-text-container"><span class="totals-text">${
          params.value
        }</span><span class="theme-tooltiptext totals-tooltip right">${TotalsDescription.getTooltip(
          params.data.name
        )}</span></span>`;
      } else if (isMasterScheduleSubtotalRow(params.data)) {
        return `<span class="theme-tooltip totals-text-container"><span class="totals-text">${
          params.value
        }</span><span class="theme-tooltiptext totals-tooltip  right">${TotalsDescription.getTooltip(
          TotalsDescription.totalSubtotals
        )}</span></span>`;
      } else if (isMasterScheduleEntryRow(params.data)) {
        let html: string = `<a class="employee-name"> ${params.value}</a><div class="ms-badge-container">`;
        if (params.data.isFromDifferntDepartment) {
          html +=
            '<span class="ms-badge diff-department-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Different department</span></span>';
        }
        if (params.data.isAgency) {
          html +=
            '<span class="ms-badge agency-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Agency employee</span></span>';
        }
        html += '</div>';
        return html;
      } else {
        return params.value;
      }
    };
  }

  public getEmployeeTypeCellRenderer(): any {
    const empTypesMap: StringMap<any> = this.empTypesMap;
    return function (params: any): string {
      if (isMasterScheduleEntryRow(params.data)) {
        const label: string = params.value;
        const description: string =
          empTypesMap && empTypesMap[label] && empTypesMap[label].description ? empTypesMap[label].description : label;
        let html: string = `<span class="ms-badge theme-tooltip totals-text-container">
              <span>${label}</span>
              <span class="theme-tooltiptext totals-tooltip right emp-type-tooltip">${description}</span>
            </span>`;
        return html;
      } else {
        return params.value;
      }
    };
  }

  public createColumnDefs(filters: MasterScheduleFilters): void {
    let empFields: any[] = [];
    this.columnDefs = [];
    empFields.push({
      headerName: 'Name',
      field: 'name',
      width: 150,
      pinned: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      cellClass: 'employee-cell',
      comparator: this.nameComparator,
      cellRenderer: this.getCellRender(filters),
    });

    empFields.push({
      headerName: 'Position Group',
      field: 'positionGroupName',
      width: 150,
      pinned: this.isMobile ? false : true,
      hide: !this.state.positionGroupColShown,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.nameComparator,
      valueGetter: (params: any): string => {
        if (params.data && params.data.position) {
          return params.data.positionGroupName;
        }
        return '';
      },
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data) || isMasterScheduleSubtotalRow(params.data)) {
          return params.value;
        } else {
          return '';
        }
      },
    });

    empFields.push({
      headerName: 'Position',
      field: 'position',
      width: 150,
      pinned: this.isMobile ? false : true,
      hide: !this.state.positionColShown,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.nameComparator,
      valueGetter: (params: any): string => {
        if (params.data && params.data.position) {
          return params.data.position.name;
        }
        return '';
      },
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data) || isMasterScheduleSubtotalRow(params.data)) {
          return params.value;
        } else {
          return '';
        }
      },
    });

    empFields.push({
      headerName: 'Date Hired',
      field: 'hireDate',
      width: 110,
      pinned: this.isMobile ? false : true,
      hide: !this.state.dateOfHireColShown,
      filter: 'agDateColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.dateComparator,
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data)) {
          return moment(params.value).format(appConfig.dateFormat);
        }
        return '';
      },
    });

    empFields.push({
      headerName: 'Date Terminated',
      field: 'dateTerminated',
      width: 110,
      pinned: this.isMobile ? false : true,
      hide: !this.state.dateTerminatedColShown,
      filter: 'agDateColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.dateComparator,
      cellRenderer: function (params: any): string {
        if (!params.value) {
          return '';
        }
        if (isMasterScheduleEntryRow(params.data)) {
          return moment(params.value).format(appConfig.dateFormat);
        }
        return '';
      },
    });

    empFields.push({
      headerName: 'Type',
      field: 'employeeType',
      width: 60,
      pinned: this.isMobile ? false : true,
      hide: !this.state.employeeTypeColShown,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      cellClass: 'emp-type-cell',
      comparator: this.nameComparator,
      valueGetter: (params: any): string => {
        if (params.data && params.data.employeeType) {
          return params.data.employeeType.name;
        }
        return '';
      },
      cellRenderer: this.getEmployeeTypeCellRenderer(),
    });

    empFields.push({
      headerName: 'Home Shift',
      field: 'homeShiftName',
      width: 150,
      pinned: this.isMobile ? false : true,
      hide: !this.state.shiftColShown,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.nameComparator,
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data)) {
          return params.value;
        }
        return '';
      },
    });

    empFields.push({
      headerName: 'Avg Weekly Hours',
      field: 'avgWeeklyRotationsHrs',
      width: 100,
      pinned: this.isMobile ? false : true,
      hide: !this.state.avgWeeklyRotationsHrs,
      filter: 'agNumberColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      comparator: this.numComparator,
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data)) {
          if (!params.value) {
            return '0';
          }
          return params.value.toFixed(2);
        }
        return '';
      },
    });

    empFields.push({
      headerName: '',
      field: 'actions',
      width: 30,
      pinned: this.isMobile ? false : true,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true,
      icons: this.getIcons(),
      sortingOrder: [null],
      cellRenderer: function (params: any): string {
        if (isMasterScheduleEntryRow(params.data)) {
          return '<div class="label label-default emp-menu">...</div>';
        }
        return '';
      },
    });

    if(this.isMobile){
      let childs =  _.cloneDeep(empFields);
      this.columnDefs.push({
        headerName: 'Employee',
        headerClass: 'slx-master-schedule-column-group',  
        headerGroupComponentFramework: MasterScheduleCustomGroupHeader,
        children: childs.filter(x=> x.field == 'name'),
      },
      {
        headerName: '',
        headerClass: 'slx-master-schedule-column-group',  
        headerGroupComponentFramework: MasterScheduleCustomGroupHeader,
        children: childs.filter(x=> x.field != 'name'),
      });
    } 
    else {
      this.columnDefs.push({
        headerName: 'Employee',
        headerClass: 'slx-master-schedule-column-group',
        headerGroupComponentFramework: MasterScheduleCustomGroupHeader,
        children: empFields,
      });  
    }
    this.createDateColumns();
  }
  public isDayColumn(colId: string): boolean {
    return colId.substr(0, 8) === 'cell.Day';
  }
  public isWeekTotalColumn(colId: string): boolean {
    return colId.substr(0, 12) === 'weeklyTotals';
  }

  public getRowStyle(params: any): any {
    if (isMasterScheduleTotalRow(params.data)) {
      let totalRow: IMasterScheduleTotalRow = params.data;
      switch (totalRow.name) {
        case TotalsDescription.totalPARLevels:
        case TotalsDescription.totalBudgetedPars:
        case TotalsDescription.totalOpenShifts:
        case TotalsDescription.totalDifference:
        case TotalsDescription.totalCensus:
        case TotalsDescription.totalPPD:
        case TotalsDescription.totalStaffingRatio:
        case TotalsDescription.totalActualHours:
        case TotalsDescription.totalActualPPD:
          if (params.node.rowIndex % 2 === 0) {
            return this.getOddTotalStyle();
          } else {
            return this.getEvenTotalStyle();
          }
        default:
          return {
            'border-top': '1px solid #a4a4a4',
            'border-bottom': '1px solid #a4a4a4',
            'background-color': '#4f4f4f',
            color: '#ffffff',
          };
      }
    }
    return '';
  }

  private getEvenTotalStyle(): any {
    return {
      'background-color': '#ffffff',
      color: '#000000',
    };
  }

  private getOddTotalStyle(): any {
    return {
      'background-color': '#dbdbdb',
      color: '#000000',
    };
  }

  private getBadges(row: MasterScheduleEntryRow, cell: MasterScheduleEntryCell): string {
    let html: string = '<div class="ms-badge-container">';
    if (cell.isScheduledToSecondaryPosition) {
      html +=
        '<span class="ms-badge secondary-position theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext wide">Scheduled to secondary position</span></span>';
    }
    if (cell.scheduledInDifferntDepartment) {
      html +=
        '<span class="ms-badge diff-department-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext wide">Scheduled in different department</span></span>';
    }
    if (cell.isOvertimeApproach) {
      html +=
        '<span class="ms-badge overtime-approach theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Approaching OT</span></span>';
    }
    if (cell.isScheduledToMultipleShifts) {
      html +=
        '<span class="ms-badge multiple-shifts theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext wide">Scheduled to multiple shifts</span></span>';
    }
    if (cell.hasConstraint) {
      html +=
        '<span class="ms-badge constraint theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Constraints</span></span>';
    }
    if (cell.isPreScheduledOvertime) {
      html +=
        '<span class="ms-badge prescheduled-overtime theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Pre Scheduled overtime</span></span>';
    }
    if (cell.isAgency || row.isAgency) {
      html +=
        '<span class="ms-badge agency-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Agency employee</span></span>';
    }
    if (cell.hasAbsence) {
      html +=
        '<span class="ms-badge absence-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Has Absence</span></span>';
    }
    if (cell.hasPartialAbsence) {
      html +=
        '<span class="ms-badge partial-absence-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext">Has Partial Absence</span></span>';
    }
    if (cell.isInQuickEdit) {
      html += `<button class="quick-edit-undo-button"><i class="fas fa-undo-alt"></i></button>`;
      //(click)="undoQuickEdit(${row.id}, ${moment(cell.dateOn).format(appConfig.dateFormat)})"
    }
    if (cell.scheduledInDifferentShiftGroup) {
      html +=
        '<span class="ms-badge diff-shift-group-badge theme-tooltip"><span class="ms-badge-tooltip theme-tooltiptext wide">Scheduled for different shift group</span></span>';
    }
    html += '</div>';
    return html;
  }
  private getStartContent(cell: MasterScheduleEntryCell): string {
    let html: string = '<div class="schedule-entry';
    if (cell.isReplaced || cell.isReplacing) {
      html += ' replaced';
    }
    html += '">';
    return html;
  }

  private getContent(content: string): string {
    return '<span class="text-place">' + content + '</span>';
  }

  private createDateColumns(): void {
    this.weeks = [];

    for (let week: number = 1; week <= this.state.numWeeks; week++) {
      this.weeks[week] = [];

      for (let i: number = 0; i < 7; i++) {
        const headerMoment = moment(moment(this.state.startDate).add(i + (week - 1) * 7, 'days'));
        let header: string = headerMoment.format('DD/ddd');

        let header1: string = headerMoment.format('DD');
        let header2: string = headerMoment.format('ddd');
        let day = moment(moment(this.state.startDate).add(i + (week - 1) * 7, 'days'));
        let fieldName: string = `cell.Day${day.format(appConfig.requestDate)}`;
        let template = `<div class="ag-cell-label-container" role="presentation">
          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
          <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">
            <span class="main-date-header-text">${header1}</span>/<span class="additional-date-header-text">${header2}<span>
            <span ref="eFilter" class="ag-header-icon ag-filter-icon" aria-hidden="true"></span>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order" aria-hidden="true"></span>
            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true"></span>
            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true"></span>
            <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true"></span>
          </div>
        </div>`;

        this.weeks[week].push({
          headerClass: 'slx-master-schedule-date-header',
          headerName: `${header1}/${header2}`,
          headerComponentParams: { template: template },
          field: fieldName,
          width: 80,
          filter: 'agTextColumnFilter',
          sortable: true,
          resizable: true,
          icons: this.getIcons(),
          cellClass: 'daily-cell',
          cellClassRules: {
            'holiday-sun': (params: any) => {
              return this.getDayNameFromCell(params) === 'Su';
            },
            'holiday-sat': (params: any) => {
              return this.getDayNameFromCell(params) === 'Sa';
            },
            'not-active-cell': (params: any) => {
              return this.isNotActiveCell(params);
            },
            'diff-department': (params: any) => {
              return this.isDifferentDepartmentScheduled(params);
            },
            'quick-edited-cell': (params: any) => {
              return this.isQuickEdited(params);
            },
          },
          //'comparator': (cell1: any, cell2: any, node1: RowNode, node2: RowNode, isInverted: boolean) => this.cellComparator(cell1, cell2, node1, node2, isInverted),
          valueGetter: (params: any): string => {
            if (isMasterScheduleEntryRow(params.data)) {
              const cell: MasterScheduleEntryCell = <MasterScheduleEntryCell>this.getCell(params);
              if (cell) {
                switch (this.state.cellDisplay) {
                  case 'shiftTimes':
                    return cell.shiftTimes;
                  case 'shiftName':
                    return cell.shiftName;
                  case 'shiftNameAndUnit':
                    return cell.shiftNameAndUnit;
                  case 'shiftDurationHours':
                    return cell.shiftDurationHours;
                  case 'shiftDurationMin':
                    return cell.shiftDurationMin;
                  default:
                    return cell.unitName;
                }
              }
            }
            if (isMasterScheduleSubtotalRow(params.data)) {
              const cell: MasterScheduleTotalCell = <MasterScheduleTotalCell>this.getCell(params);
              if (cell) {
                return cell.value;
              }
            }
            if (isMasterScheduleTotalRow(params.data)) {
              const cell: MasterScheduleTotalCell = <MasterScheduleTotalCell>this.getCell(params);
              if (cell) {
                return cell.value;
              }
            }
            return '';
          },
          cellRenderer: (params: any): string => {
            if (isMasterScheduleEntryRow(params.data)) {
              ///const cell: MasterScheduleEntryCell = params.value;
              if (this.isNotActiveCell(params)) {
                return '';
              }
              const cell: MasterScheduleEntryCell = <MasterScheduleEntryCell>this.getCell(params);
              if (cell) {
                switch (this.state.cellDisplay) {
                  case 'shiftTimes':
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.shiftTimes) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                  case 'shiftName':
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.shiftName) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                  case 'shiftNameAndUnit':
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.shiftNameAndUnit) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                  case 'shiftDurationHours':
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.shiftDurationHours) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                  case 'shiftDurationMin':
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.shiftDurationMin) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                  default:
                    return (
                      this.getStartContent(cell) +
                      this.getContent(cell.unitName) +
                      this.getBadges(params.data, cell) +
                      '</div>'
                    );
                }
              }
            }
            if (isMasterScheduleSubtotalRow(params.data)) {
              const cell: MasterScheduleTotalCell = <MasterScheduleTotalCell>this.getCell(params);
              if (cell) {
                return '<div class="subtotal-entry">' + cell.value + '</div>';
              }
            }
            if (isMasterScheduleTotalRow(params.data)) {
              const cell: MasterScheduleTotalCell = <MasterScheduleTotalCell>this.getCell(params);
              if (cell) {
                if (params.data.name === TotalsDescription.totalTotals) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                if (params.data.name === TotalsDescription.totalPARLevels) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                if (params.data.name === TotalsDescription.totalOpenShifts) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                if (params.data.name === TotalsDescription.totalDifference) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                if (params.data.name === TotalsDescription.totalBudgetedPars) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                if (params.data.name === TotalsDescription.totalCensus) {
                  return '<div class="total-entry total-linked-entry">' + cell.value + '</div>';
                }
                return '<div class="total-entry">' + cell.value + '</div>';
              }
            }
            return '';
          },
        });
      }
      let header: string = 'T';
      let fieldName: string = 'weeklyTotals.' + (week - 1).toString();

      this.weeks[week].push({
        headerName: header,
        field: fieldName,
        width: 50,
        filter: 'agNumberColumnFilter',
        hide: !this.state.weeklyTotals,
        sortable: true,
        resizable: true,
        icons: this.getIcons(),
        cellRenderer: function (params: any): string {
          if (params.value == null) {
            if (isMasterScheduleSubtotalRow(params.data) || isMasterScheduleTotalRow(params.data)) {
              return '';
            }
            const zero = 0;
            return '<div class="label label-default full-width" >' + zero.toFixed(2) + '</div>';
          }
          if (_.isNumber(params.value)) {
            return '<div class="label label-default full-width" >' + params.value.toFixed(2) + '</div>';
          }
          return '<div class="label label-default full-width" >' + params.value + '</div>';
        },
        comparator: this.numComparator,
      });

      let weekStartHeader: string = moment(moment(this.state.startDate).add((week - 1) * 7, 'days')).format(
        'MMM.D,YYYY'
      );
      let weekEndHeader: string = moment(moment(this.state.startDate).add(6 + (week - 1) * 7, 'days')).format(
        'MMM.D,YYYY'
      );
      this.columnDefs.push({
        headerClass: 'slx-master-schedule-column-group',
        headerName: `Week ${week.toString()}:`,
        headerGroupComponentFramework: MasterScheduleCustomGroupHeader,
        headerGroupComponentParams: {
          additionalInfo: `${weekStartHeader}-${weekEndHeader}`,
        },
        children: this.weeks[week],
      });
    }
  }

  private cellComparator(cell1: any, cell2: any, node1: RowNode, node2: RowNode, isInverted: boolean): number {
    const res = totalComparator(cell1, cell2, node1, node2, isInverted);
    if (res !== undefined) {
      return res;
    }

    if (cell1 === null && cell2 === null) {
      return 0;
    }
    if (cell1 === null) {
      return -1;
    }
    if (cell2 === null) {
      return 1;
    }

    if (isMasterScheduleEntryCell(cell1) && isMasterScheduleEntryCell(cell2)) {
      let c1: MasterScheduleEntryCell = <MasterScheduleEntryCell>cell1;
      let c2: MasterScheduleEntryCell = <MasterScheduleEntryCell>cell2;
      switch (this.state.cellDisplay) {
        case 'shiftTimes':
          return c1.shiftTimes > c2.shiftTimes ? 1 : -1;
        case 'shiftName':
          return c1.shiftName > c2.shiftName ? 1 : -1;
        case 'shiftNameAndUnit':
          return c1.shiftNameAndUnit > c2.shiftNameAndUnit ? 1 : -1;
        case 'shiftDurationHours':
          return c1.shiftDurationHours > c2.shiftDurationHours ? 1 : -1;
        case 'shiftDurationMin':
          return c1.shiftDurationMin > c2.shiftDurationMin ? 1 : -1;
        default:
          return c1.unitName > c2.unitName ? 1 : -1;
      }
    }
    return 0;
  }

  private dateComparator(date1: string, date2: string, node1: RowNode, node2: RowNode, isInverted: boolean): number {
    const res = totalComparator(date1, date2, node1, node2, isInverted);
    if (res !== undefined) {
      return res;
    }

    if (date1 === null && date2 === null) {
      return 0;
    }
    if (date1 === null) {
      return -1;
    }
    if (date2 === null) {
      return 1;
    }

    if (moment(date1).format(appConfig.serverDateFormat) > moment(date2).format(appConfig.serverDateFormat)) return 1;
    else return -1;
  }

  private nameComparator(name1: string, name2: string, node1: RowNode, node2: RowNode, isInverted: boolean): number {
    const res = totalComparator(name1, name2, node1, node2, isInverted);
    if (res !== undefined) {
      return res;
    }

    if (name1 > name2) return 1;
    else return -1;
  }

  private numComparator(value1: string, value2: string, node1: RowNode, node2: RowNode, isInverted: boolean): number {
    const res = totalComparator(value1, value2, node1, node2, isInverted);
    if (res !== undefined) {
      return res;
    }

    if (value1 === null && value2 === null) return 0;

    if (isNaN(parseInt(value1))) return -1;

    if (isNaN(parseInt(value2))) return 1;

    if (parseInt(value1) > parseInt(value2)) return 1;
    else return -1;
  }
  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }
}
