import { TimecardsApiService } from './../../../../../time-and-attendance/services/timecards/timecards-api.service';
import { EmployeeRehireDialogComponent } from './../../../../employee/components/employee-activities/employee-rehire/employee-rehire-dialog/employee-rehire-dialog.component';
import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, EventEmitter, Inject, SimpleChanges } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute,NavigationExtras } from '@angular/router';
import { EmployeeSectionsProfile, EmployeeSectionsTemporalModel, EmployeeSectionsBase, BenefitClassDialogReq, EmployeeSectionsContacts } from '../../../models/index';
import { EmployeeSectionsPersonalApiService, EmployeeSectionBridgeService } from '../../../services/index';
import { AccessibleService, EmployeeDefinitionsApiService, LookupApiService, LookupService } from '../../../../../organization/services/index';
import { Lookup } from '../../../../../organization/models/index';
import { NotificationsService } from '../../../../../core/components/index';
import { EmployeeTerminateDialogComponent, EmployeeTransferDialogComponent, ChangeTerminationDateDialogComponent } from '../../../../employee/components/index';
import { EmployeeToTerminate, EmployeeTerminate, EmployeeToTransfer, EmployeeTransfer, EmployeeTransferringResponse, EmployeeRehire, EmployeeToRehire } from '../../../../employee/models/index';
import { EmployeeTerminateApiService, EmployeeTransferApiService, EmployeeRehireApiService } from '../../../../employee/services/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { InfoDialogComponent, EmployeeListNavigationService, EmployeeSectionNavigationService, ConfirmOptions, ConfirmDialogComponent } from '../../../../../common/index';
import { AgencyEmployeeApiService } from '../../../../../configuration/services/index';
import { BenefitClassDialogComponent } from '../../benefit-class-dialog/benefit-class-dialog.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { Subscription } from 'rxjs';
import {unsubscribe } from '../../../../../core/decorators/index';
import { employeeConfig } from '../../../../employee/employee.config';
import { SessionService } from '../../../../../../app/core/services/session/session.service';
@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-profile',
  templateUrl: 'employee-sections-profile.component.html',
  styleUrls: ['employee-sections-profile.component.scss']
})
export class EmployeeSectionsProfileComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input() public employeeSectionsProfile: EmployeeSectionsProfile;
  @Input() public employeeId: number;
 
  @Output()
  public updateBanner: EventEmitter<EmployeeSectionsProfile>;
  public isV6ReHireEnhacementEnabled: boolean = false;
  public isNGPUser: boolean = false;
  public isSystemUser: boolean = false;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form')
  public ngForm: NgForm;

  public disabilityStatusesLookup: Lookup;
  public hourlyRateLimit: number = 9999.99;
  public weeklyRateLimit: number = 399999.99;

  public bswiftEnabled: boolean = false;

  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private employeePhoto: string;
  private domSanitizer: DomSanitizer;
  private employeeTerminateApiService: EmployeeTerminateApiService;
  private employeeTransferApiService: EmployeeTransferApiService;

  private employeeSectionBridgeService: EmployeeSectionBridgeService;

  private modalService: ModalService;

  public maxBirthDate: Date;


  @unsubscribe()
  private contactSubscriptions: Subscription;

  @unsubscribe()
  private hireDateSubscriptions: Subscription;

  constructor(
    employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    employeeTerminateApiService: EmployeeTerminateApiService,
    employeeTransferApiService: EmployeeTransferApiService,
    modalService: ModalService,
    domSanitizer: DomSanitizer,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private employeeRehireApiService: EmployeeRehireApiService,
    private agencyEmployeeApiService: AgencyEmployeeApiService,
    private router: Router,
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private appSettingsManageService: AppSettingsManageService,
    private notificationService: NotificationsService,
    @Inject(DOCUMENT)
    private document: Document,
    employeeSectionBridgeService: EmployeeSectionBridgeService,
    private lookupApiService: LookupApiService,
    private timecardsApi: TimecardsApiService,
    private accessibleService: AccessibleService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) {
    super(decorator, ngZone);
    this.domSanitizer = domSanitizer;
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
    this.employeeTransferApiService = employeeTransferApiService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.employeeTerminateApiService = employeeTerminateApiService;
    this.modalService = modalService;
    this.updateBanner = new EventEmitter<EmployeeSectionsProfile>();
    this.disabilityStatusesLookup = this.getDisabilityStatusesLookup();
    this.employeeSectionBridgeService = employeeSectionBridgeService;
    this.maxBirthDate = moment().subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsProfile;
  }
  
  public get getRateLimit(): number {
    const isSalaryPayType = this.employeeShortInfo &&
            this.employeeShortInfo.payType &&
            this.employeeShortInfo.payType.isSalaryPayType;
    return isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
    
  }
  public ngOnInit(): void {
    super.ngOnInit();
    this.getSettings();

    this.contactSubscriptions=this.employeeSectionBridgeService.subscribeTorReloadProfileSection((contact: EmployeeSectionsContacts)=>{
      this.employeeSectionsProfile.mobilePhone = contact.mobile;
      this.employeeSectionsProfile.phone = contact.phone;
    });

    this.hireDateSubscriptions=this.employeeSectionBridgeService.subscribeToChangeHireDate((hireDate: Date)=>{
      this.employeeSectionsProfile.hireDate.fieldValue=hireDate;
      this.maxBirthDate = moment(hireDate).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeSectionsProfile']) {
      if(this.employeeSectionsProfile && this.employeeSectionsProfile.birthDate.fieldValue){
        this.employeeSectionBridgeService.changeBirthDate(this.employeeSectionsProfile.birthDate.fieldValue);
      }
    }
  }

  public onTerminate(ignoreLockedPayrollCycles: boolean): void {
    let req: EmployeeToTerminate = new EmployeeToTerminate();
    req.employeeId = this.employeeId;
    req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
    req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
    req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
    req.suffix = this.employeeSectionsProfile.suffix.fieldValue;
    req.fullName = this.employeeShortInfo.fullName;
    req.ignoreLockedPayrollCycles = ignoreLockedPayrollCycles;
    req.rehireDate = this.employeeSectionsProfile.rehireDate;
    EmployeeTerminateDialogComponent.openDialog(req, this.modalService, (result: boolean, res: EmployeeTerminate) => {
      if (result && res) {
        this.employeeSectionsProfile.terminationDate = res.date;
        this.startProgress();
        this.employeeTerminateApiService.terminateEmployee(res)
          .then((status: any) => {
            this.loadSubsection();           
            this.notificationService.success('Employee Terminated', 'Employee has terminated successfully');
          })
          .catch(() => {
            this.stopProgress();
          });
      }
    });
  }

  public onCancelTerminate(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    if(this.isSystemUser){ //It is true when NGP flag is true
      options.showCancel = true;
      options.showOK = true;
      options.width = 350;
      options.height = 240;
      options.notes = 'This action will not reactive the employee in CarePay, please manually reactivate the employee in CarePay to make sure the records in both systems are in sync';
      ConfirmDialogComponent.openDialog(
        'Cancel Employee Termination', `Do you want to cancel termination for ${this.employeeShortInfo.fullName}`,
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.cancelTermination();
          }
        },
        options
      ); 
    }
    else{
      this.modalService.globalAnchor.openConfirmDialog('Cancel Employee Termination', `Do you want to cancel termination for ${this.employeeShortInfo.fullName}`, (result: boolean) => {
        if (result) {
          this.cancelTermination();
        }
      });
    }
  }

  public cancelTermination(){
    this.startProgress();
    this.employeeTerminateApiService.cancelEmployeeTermination(this.employeeId)
      .then((status: any) => {
        this.loadSubsection();            
      })
      .catch(() => {
        this.stopProgress();
      });
  }

  public onChangeTerminationDate(ignoreLockedPayrollCycles: boolean): void {
    let req: EmployeeToTerminate = new EmployeeToTerminate();
    req.employeeId = this.employeeId;
    req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
    req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
    req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
    req.terminationDate = this.employeeSectionsProfile.terminationDate;
    req.fullName = this.employeeShortInfo.fullName;
    req.ignoreLockedPayrollCycles = ignoreLockedPayrollCycles;
    req.isNGPUser = this.isNGPUser;
    req.isSystemUser = this.isSystemUser;
    ChangeTerminationDateDialogComponent.openDialog(req, this.modalService, (result: boolean, res: EmployeeTerminate) => {
      if (result && res) {
        this.employeeSectionsProfile.terminationDate = res.date;
        this.startProgress();
        this.employeeTerminateApiService.changeTerminationDate(res.employeeId, res.date)
          .then((status: any) => {
            this.loadSubsection();
          })
          .catch(() => {
            this.stopProgress();
          });
      }
    });
  }

  public onResendTimeclocksEmail(): void {
    this.modalService.globalAnchor.openConfirmDialog('Confirm Action', 'Do you want resend timeclocks email to this employee?', (result: boolean) => {
      if (result) {
        this.startProgress();
        this.agencyEmployeeApiService.sendTimeclocksEmailToAgencyEmployee(this.employeeId).then(() => {
          this.stopProgress();
        }).catch(() => {
          this.stopProgress();
        });
      }
    });
  }

  public onTransfer(): void {
    let req: EmployeeToTransfer = new EmployeeToTransfer();
    req.employeeId = this.employeeId;
    req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
    req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
    req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
    req.suffix = this.employeeSectionsProfile.suffix.fieldValue;
    req.organization = this.employeeSectionsProfile.organization.fieldValue;
    req.organizationId = this.employeeSectionsProfile.organizationId;
    req.department = this.employeeSectionsProfile.department.fieldValue;
    req.departmentId = this.employeeSectionsProfile.departmentId;
    req.position = this.employeeSectionsProfile.position.fieldValue;
    req.positionId = this.employeeSectionsProfile.positionId;
    req.hireDate = this.employeeSectionsProfile.hireDate.fieldValue;
    req.fullName = this.employeeShortInfo.fullName;
    req.employeeType = this.employeeShortInfo.type;
    req.payrollNumber = this.employeeShortInfo.payrollNumber;
    EmployeeTransferDialogComponent.openDialog(req, this.modalService, (result: boolean, res: EmployeeTransfer) => {
      if (result && res) {
        this.startProgress();
        this.employeeTransferApiService.transferEmployee(res)
          .then((data: EmployeeTransferringResponse) => {
            this.notificationService.success('Employee Transferred', 'Employee has transferred successfully');
          })
          .then(() => {
            this.document.location.reload();
          })
          .catch(() => this.stopProgress());
      }
    });
  }

  public undoTransfer (): void {
    this.modalService.globalAnchor.openConfirmDialog ('Are you sure?', 'Do you want to undo transfer of employee?', (result: boolean)=> {
      if (result) {
        this.startProgress();
        this.employeeTransferApiService.undoTransferEmployee(this.employeeId)
          .then(() => {
            this.stopProgress();
            let nav: EmployeeListNavigationService = new EmployeeListNavigationService(this.router, this.route);
            nav.navigateToEmployeeList();
          })
          .catch(() => this.stopProgress());
      }
    });
  }

  public onRehire(): void {
 
    let req: EmployeeToRehire = new EmployeeToRehire();
    req.employeeId = this.employeeId;
    req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
    req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
    req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
    req.terminationDate = this.employeeSectionsProfile.terminationDate;
    req.departmentId = this.employeeSectionsProfile.departmentId;
    req.mobilePhone = this.employeeSectionsProfile.mobilePhone.fieldValue;
    req.orgLevelId = this.employeeShortInfo.position.orgLevelId;
    req.fullName = this.employeeShortInfo.fullName;
    req.payRateLimit = this.getRateLimit;
    req.dialogHeight = this.isV6ReHireEnhacementEnabled ? 790 : 510;
    req.employeeType = this.employeeShortInfo.type;
    req.organizationId = this.employeeSectionsProfile.organizationId;
    req.payrollNumber = this.employeeShortInfo.payrollNumber;
    EmployeeRehireDialogComponent.openDialog(req, this.modalService, (result: boolean, res: EmployeeRehire) => {
      if (result && res) {
        if (result && res) {
          this.startProgress();
          let newRehireParam = this.isV6ReHireEnhacementEnabled ? 'enhancement':'';
          this.employeeRehireApiService.rehireEmployee(res, newRehireParam)
          .then((empId) => {
            if(!this.isV6ReHireEnhacementEnabled){
              this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
              this.employeeSectionBridgeService.updateContactSection(res.mobilePhone);
              this.loadSubsection();                
            }
            else{
              if(this.employeeId == empId){
                this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
                this.employeeSectionBridgeService.updateContactSection(res.mobilePhone);
                this.loadSubsection();                 
              }
              else{
                this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
                setTimeout(() => {
                  let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
                  const urlTree = navService.getUrlTreeFromRoot(empId, true);
                  const extras: NavigationExtras = {
                    skipLocationChange: false,
                    replaceUrl: false,
                  };
                  this.router.navigateByUrl(urlTree, extras);
                }, 1000);
              }
            }
            })
            .catch(() => this.stopProgress());
        }
      }
    });
  }

  public get employeeFullName(): string {
    if(!this.employeeSectionsProfile.fullName.fieldValue)
      return this.employeeSectionsProfile.lastName.fieldValue + ', ' + this.employeeSectionsProfile.firstName.fieldValue + ' ' + this.employeeSectionsProfile.middleName.fieldValue;
    else
      return this.employeeSectionsProfile.fullName.fieldValue;
  }

  public get statusDateLabel(): string {
    if (this.employeeSectionsProfile && this.employeeSectionsProfile.isTransferred)
      return 'Date of Transfer';
    return 'Date of Termination';
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalProfile(this.employeeId)
      .then((employeeSectionsProfile: EmployeeSectionsProfile) => {
        this.employeeSectionsProfile = employeeSectionsProfile;
        this.updateBanner.emit(employeeSectionsProfile);
        this.employeeSectionBridgeService.changeBirthDate(this.employeeSectionsProfile.birthDate.fieldValue);
        this.stopProgress();
      });
  }

  protected doSave(effectiveDate: Date): void {
    if (this.bswiftEnabled &&
      !this.employeeShortInfo.avoidsBswiftChecks &&
      this.employeeSectionsProfile.areBenefitsFieldsDirty()
    ) {
      this.triggerBenefitClassPopup(effectiveDate);
    } else {
      this.savePersonalProfile(effectiveDate);
    }
  }

  private savePersonalProfile(effectiveDate: Date): Promise<any> {
    this.startProgress();
    return this.employeeSectionsPersonalApiService.setPersonalProfile(this.employeeId, this.employeeSectionsProfile, effectiveDate)
      .then((status: any) => {
        this.employeeDefinitionsApiService.clearEmployeeShortInfoCache(this.employeeId);
        // Once an employee information changes, invalidating all the cached objects
        this.lookupApiService.clearEmployeeCacheData();
        this.lookupApiService.clearPositionsCacheData();
        this.timecardsApi.clearIndividualTimecardsCacheData();
        this.accessibleService.clearCheckedByOrglevelCachedData();
        this.accessibleService.clearCheckedByEmployeeCachedData();
        this.onActionComplete(true);
        this.loadSubsection();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsProfile);
  }

  private getDisabilityStatusesLookup(): Lookup {
    return this.lookupService.getDisabilityLookup();
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.bswiftEnabled = config.bswiftIntegrationEnabled;
    this.isV6ReHireEnhacementEnabled = config.isV6ReHireEnhacementEnabled;
    this.isNGPUser =  config.IsNextgenPayrollEnabled;
    if(this.isNGPUser){
      this.isSystemUser = this.sessionService.getUser() && this.sessionService.getUser().username.toLowerCase() === 'system' ? true : false;
    } 
  }

  private async triggerBenefitClassPopup(effectiveDate: Date): Promise<void> {
    this.startProgress();
    const canEditBenefitClass = await this.employeeDefinitionsApiService.getCanEditBenefitClass(this.employeeId);

    if (canEditBenefitClass) {
      const request = new BenefitClassDialogReq();
      request.employeeId = this.employeeId;
      request.hireDate = this.employeeShortInfo.dateHired;
      request.employeeBenefitClass = await this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employeeId, moment().startOf('day').toDate());
      if(this.employeeSectionsProfile.status.toLowerCase() === "future rehire"){
        request.rehireDate = this.employeeSectionsProfile.rehireDate;
      }
      this.stopProgress();

      BenefitClassDialogComponent.OpenDialog(request, this.modalService, (result: boolean) => {
        if (result) {
          this.savePersonalProfile(effectiveDate);
        } else {
          this.loadSubsection();
        }
      });
    } else {
      this.stopProgress();

      await this.savePersonalProfile(effectiveDate);

      InfoDialogComponent.OpenDialog(
        'Warning',
        'The changes you have made to the employee record could prompt a change to the employee Benefit Class. Please contact your Benefits Administrator and advise them of the changes.',
        this.modalService
      );
    }
  }
}
